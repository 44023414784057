.signBorder {
  border-bottom: 1px solid rgba(117, 117, 117, 0.685);
}

.input {
  border: none;
  border: 1px solid var(--color-primary-700);
  color: var(--color-primary-700);
  font-size: 1.2rem;
  font-weight: 500;
  padding-inline: 0.3rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  outline: none;
  transition: all 0.3s ease;
}

.signDraw {
  height: 50vh;
  position: relative;
}

.chooseCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border: 1px solid rgba(117, 117, 117, 0.685);
  height: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 10rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.canvasBorder {
  border: 1px solid rgba(150, 150, 150, 0.514);
  border-radius: 0.5rem;
  margin: 1rem;
}
