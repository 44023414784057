.loginContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #282c34;
  padding: 2rem;
}

.title {
  position: absolute;
  top: 5%;
  left: 12%;
}

.title p {
  font-size: 2rem;
  color: rgb(255, 255, 255);
}

.border {
  border-radius: 5px;
  height: 60vh;
  width: 1px;
  background-color: #ffffff;
}

.title p::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgb(0, 0, 0);
  /* margin-top: 1rem; */
}

.lottie {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 60%;
  margin: auto;
  border-bottom: 10%;
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.right {
  flex: 1;
}

.card {
  height: 450px;
  width: 500px;
  border-radius: 10px;
  /* background-color: rgba(255, 255, 255, 0.76); */
  background-color: #1b1b1bcc;
  position: relative;
  box-shadow: 0px 0px 10px #000000;
}

.upperInfo {
  font-size: 1.4rem;
  text-align: center;
  padding-top: 1rem;
  color: rgb(255, 255, 255);
}

.lowerInfo {
  font-size: 1rem;
  text-align: center;
  padding-top: 0.7rem;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}

.lowerInfo span {
  color: rgb(146, 144, 144);
  cursor: pointer;
  text-decoration: underline;
}

.lowerInfo span:hover {
  color: rgb(0, 140, 255);
  text-decoration: underline;
}

.loginForm {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputFeild {
  margin: 15px 0;
  padding: 15px 10px;
  width: 85%;
  outline: none;
  border: 1px solid #12337549;
  border-radius: 10px;
  display: inline-block;
  transition: 0.2s ease all;
  color: black;
  font-size: 1.1rem;
}

.feild {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.label {
  width: 85%;
}

.feild p {
  font-size: 1.1rem;
  color: #ffffff;
  width: max-content;
  align-items: left;
}

.feild p::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #cadcff;
}

.btn {
  width: 83%;
  height: 3.125rem;
  cursor: pointer;
  line-height: 3.125rem;
  vertical-align: middle;
  padding: 0 1.5625rem;
  font-size: 1.3rem;
  letter-spacing: 0.125rem;
  padding-left: calc(0.125rem + 1.5625rem);
  font-weight: 700;
  border-radius: 0.5rem;
  transition: color 0.25s ease, background-color 0.25s ease,
    border-color 0.25s ease;
  border: none;
  color: rgba(255, 255, 255, 0.479);
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282c34a2;
}

.btn:hover {
  background-color: #282c34;
  color: #ddd;
}
.loader {
  margin-left: 1rem;
  color: black !important;
}
