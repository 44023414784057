/* @import url("https://cdnjs.cloudflare.com/ajax/libs/inter-ui/3.18.0/inter.css"); */
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Covered+By+Your+Grace&family=Give+You+Glory&family=Great+Vibes&family=Monsieur+La+Doulaise&family=Mr+Dafoe&family=Rock+Salt&family=Sacramento&family=Stalemate&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/utilities";

h1,
h2,
h3,
h4,
p {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
p.bold {
  font-weight: 700;
}

:root {
  font-size: 14px;
  --color-white: #fff;
  --color-bg: #e9e9e9;
  --color-button-text: #fff;
  --color-primary-100: #dee3ea;
  --color-primary-200: #b2bdcd;
  --color-primary-300: #5d7290;
  --color-primary-600: #323d4d;
  --color-primary-700: #242c37;
  --color-primary-800: #151a21;
  --color-primary-900: #0b0e11;
  --color-secondary-washed-out: #879eed;
  --color-secondary: #4a6ffd;
  --color-accent-glow: rgba(253, 77, 77, 0.3);
  --color-accent-gradient: linear-gradient(
    90deg,
    rgba(255, 114, 110, 1) 0%,
    rgba(224, 68, 44, 1) 40%,
    rgba(74, 111, 253, 1) 69%,
    rgba(74, 111, 253, 1) 79%
  );
  --color-accent: #e0442c;
  --color-accent-hover: #fd6868;
  --color-accent-disabled: #f5bfbf;
  --screen-height-reduction: 0px;
  --color-primary-100-translucent: rgba(222, 227, 234, 0.15);
}

input:focus {
  text-decoration: none;
  outline: none;
}
h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.4rem;
}

p {
  font-size: 1rem;
  font-weight: 500;
}

p.small {
  font-size: 0.85rem;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-700) rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  overflow: overlay;
  width: 5px;
}

::-webkit-scrollbar-track {
  display: initial;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-700);
  border-radius: 0px;
}

html,
body,
.__next {
  /* background-color: #0a0f14; */
  background-color: var(--color-bg);
  height: 100%;
  width: 100%;
  display: flex;
  color: white;
  /* font-family: "Open Sans", "Alex Brush", "Covered By Your Grace",
    "Give You Glory", "Great Vibes", "Monsieur La Doulaise", "Mr Dafoe",
    "Rock Salt", "Sacramento", "Stalemate", cursive, sans-serif; */
}

.AlexBrush {
  font-family: "Alex Brush", cursive;
}

.CoveredByYourGrace {
  font-family: "Covered By Your Grace", cursive;
}

.GiveYouGlory {
  font-family: "Give You Glory", cursive;
}

.GreatVibes {
  font-family: "Great Vibes", cursive;
}

.MonsieurLaDoulaise {
  font-family: "Monsieur La Doulaise", cursive;
}

.MrDafoe {
  font-family: "Mr Dafoe", cursive;
}
.RockSalt {
  font-family: "Rock Salt", cursive;
}
.Sacramento {
  font-family: "Sacramento", cursive;
}

.Stalemate {
  font-family: "Stalemate", cursive;
}

audio {
  width: 0;
  display: none !important;
}

html {
  position: fixed;
}

.__next {
  overflow-y: auto;
}

::-webkit-resizer {
  background: var(--color-primary-700);
}

#nprogress {
  position: relative;
  z-index: 9999999;
}

#nprogress .bar {
  background: var(--color-accent-hover) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--color-accent-hover),
    0 0 5px var(--color-accent-hover) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--color-accent-hover) !important;
  border-left-color: var(--color-accent-hover) !important;
}

img.emoji {
  height: 1.2rem;
  width: 1.2rem;
  margin: 0 0.05em 0 0.1em;
  top: -0.1em;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  cursor: text;
}

.h-screen {
  height: calc(100vh - var(--screen-height-reduction));
}

button:focus {
  outline: none;
}

.text-bg {
  background: var(--color-accent-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ribbon {
  background: var(--color-accent-gradient);
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  right: -55px;
  bottom: 30px;
  transform: rotate(-45deg);
  box-shadow: 0 0 10px rgb(49, 49, 49);
}

.ribbon a {
  border: 1px solid rgb(88, 88, 88);
  color: #fff;
  display: block;
  margin: 1px 0;
  text-align: center;
  padding: 10px 50px;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

#lottie-Landing-animation > * > * {
  transform: none !important;
}

.line-height-username1 {
  line-height: 3.75rem;
}

.home-cards {
  height: 8rem;
}

.main-step {
  position: relative;
}

.main-step::after {
  content: "→";
  color: #0b0e11;
  position: absolute;
  right: 0;
  font-size: 2em;
}

.actionBtn {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f61919d5;
  border: 2px solid #ca1818;
  border-radius: 0.3em;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 0.7em 1.1em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainTitle {
  --padding: 0rem;
  position: relative;
  width: max-content;
}

.mainTitle::after {
  content: "";
  position: absolute;
  height: 4px;
  border-radius: 5px;
  left: 0;
  top: 20px;
  width: 50%;
  background: rgb(255, 0, 0);
  transform-origin: center;
  transform: scaleX(0);
  transition: all 400ms ease;
}

.mainTitle.active::after {
  transform: scaleX(1);
}

.MuiDialog-paper {
  background-color: var(--color-bg);
}
