.wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.05);
}
.wrapper header {
  color: #6990f2;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}
.upForm {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 2px dashed #6990f2;
  width: 100%;
  margin: 0 auto;
}

.upForm p {
  font-size: 12px;
}
section .row {
  margin-bottom: 10px;
  background: #e9f0ff;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
section .row i {
  color: #6990f2;
  font-size: 20px;
}
section .details span {
  font-size: 14px;
}
.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}
.progress-area .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #6990f2;
  border-radius: inherit;
}
.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress {
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
  width: 0px;
}
.uploaded-area .row .content {
  display: flex;
  align-items: center;
}
.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size {
  color: #404040;
  font-size: 10px !important;
}
