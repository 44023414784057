@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: #fff; */
}

#root {
  /* display: flex; */
  height: 100%;
  width: 100%;
}
